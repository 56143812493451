import React, { createContext, useState, useContext } from 'react';
import config from '@app/config';
import type { ShopState } from './types';

export const ShopContext = createContext<ShopState>(null);

export const ShopProvider: React.FC = ({ children }) => {
  const [state] = useState<ShopState>({ ...config });

  return (
    <ShopContext.Provider value={{ ...state }}>{children}</ShopContext.Provider>
  );
};

export function useShop(): ShopState {
  return useContext(ShopContext);
}
