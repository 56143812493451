export interface Config {
  store: string;
  title: string;
  domain: string;
  canonical: string;
  uuid_email_namespace: string;
  seo: { [key: string]: string };
  support: { [key: string]: string };
  globalBanner?: { [key: string]: string };
}

const config: Config = {
  store: 'overourmoon',
  title: 'OverOurMoon™',
  domain: 'overourmoon.com',
  canonical: 'https://overourmoon.com',
  uuid_email_namespace: 'd707eab3-3681-5d7e-aaf6-46ed331680e3',
  support: { email: 'help@overourmoon.com', phone: '+1 (619) 241-4828' },
  seo: {
    pageTitle: 'Custom Moon Maps',
    description: '',
    'p:domain_verify': '',
    'og:locale': 'en_US',
    'og:shop_name': 'OverOurMoon™',
    'og:image': '',
    'og:type': 'website',
    'og:description': '',
  },
  globalBanner: {
    message: 'BLACK FRIDAY ALL MONTH - BUY 2, GET 1 FREE! CODE: B2G1FREE',
    variant: 'alert', // "info" | "warning" | "alert"  (blue, yellow, red)
    size: 'sm', // "sm" | "md" | "lg"
    bold: 'false', // true | false
  },
};

export default config;
